var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('article',{staticClass:"row",staticStyle:{"margin-top":"10px","background":"linear-gradient(#1d1d1d, black)"},style:({ borderTop: ("2px solid " + (this.secondaryColor)) })},[_c('section',{staticClass:"text-center col-lg-8 col-sm-12"},[_c('div',{staticClass:"centered",staticStyle:{"width":"100%","height":"320px"},style:({
          background: ("linear-gradient(" + (this.brandColor) + ", " + (this.secondaryColor) + ")"),
        })},[_c('ResponsiveImage',{attrs:{"clamp":true,"ws":315,"hs":300,"image":_vm.eventsBrand.metadata.main_image.imgix_url,"alt":_vm.eventsBrand.title}})],1),_c('h1',{staticClass:"tulum-party-title",staticStyle:{"margin-top":"35px","z-index":"10","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.eventsBrand.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.brandCaption))]),_c('div',[(this.legend)?_c('h5',{staticStyle:{"margin-top":"-25px"}},[_vm._v(" "+_vm._s(this.legend)+" ")]):_vm._e()]),(_vm.incomingParties.length)?_c('div',[_c('router-link',{staticClass:"text-left",attrs:{"to":{ path: '/event/' + _vm.incomingParties[0].slug }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.logo + '?w=40w=40&fit=clamp'),expression:"this.logo + '?w=40w=40&fit=clamp'"}],staticStyle:{"border-radius":"100px","margin-right":"-20px","z-index":"2"},attrs:{"height":"40px","width":"40px","alt":_vm.eventsBrand.title}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              _vm.incomingParties[0].metadata.main_image.imgix_url +
              '?w=40w=40&fit=clamp'
            ),expression:"\n              incomingParties[0].metadata.main_image.imgix_url +\n              '?w=40w=40&fit=clamp'\n            "}],staticStyle:{"border-radius":"100px"},attrs:{"alt":'Upcoming Event: ' + _vm.incomingParties[0].title,"height":"40px","width":"40px"}}),_vm._v(" Upcoming Events "),_c('span',{staticClass:"badge"},[_vm._v("("+_vm._s(_vm.incomingParties.length)+")")])])],1):_vm._e(),_c('section',{staticClass:"card",staticStyle:{"margin-bottom":"1px","padding":"10px","margin-top":"20px","min-height":"400px"},style:({
          background: ("linear-gradient(" + (this.brandColor) + "," + (this.secondaryColor) + ")"),
        })},[_c('div',{staticStyle:{"background-color":"#1b1b1b","opacity":"0.8","padding":"15px"},domProps:{"innerHTML":_vm._s(_vm.eventsBrand.content)}})]),_c('IncomingParty',{attrs:{"incoming-party":_vm.incomingParties[0],"logo":this.logo,"color":this.secondaryColor}}),(_vm.incomingParties.length)?_c('section',{staticClass:"row",staticStyle:{"padding":"10px"},style:({ background: ("linear-gradient(" + (this.brandColor) + ", black)") }),attrs:{"id":"incoming-events"}},[_c('h4',{staticClass:"section-header text-left"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.logo + '?w=40&h=40&fit=clamp'),expression:"this.logo + '?w=40&h=40&fit=clamp'"}],staticStyle:{"border-radius":"300px"},attrs:{"height":"40px","width":"40px"}}),_vm._v("Upcoming Events ("+_vm._s(_vm.incomingParties.length)+") ")]),(_vm.incomingParties.length)?_c('br'):_vm._e(),_c('p',{staticStyle:{"margin-left":"50px","font-size":"0.6rem","margin-top":"-35px"}},[_vm._v(" TICKETS & EVENT DETAILS ")]),(_vm.incomingParties.length)?_c('br'):_vm._e(),(_vm.incomingParties.length)?_c('br'):_vm._e(),_vm._l((_vm.incomingParties),function(event,idx){return _c('MiniEventCard',{key:event._id,staticClass:"card-animation-on-hover animation-transition-general animate-bounce",attrs:{"large":"true","event":event,"with-venue":"true","short":"true","idx":idx}})}),_c('br'),_c('div',{staticClass:"card centered",staticStyle:{"height":"250px","width":"260px","margin-top":"30px"},style:({
            background: ("linear-gradient(" + (this.brandColor) + ", black)"),
          })},[_vm._m(0),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.astroImg + '?h=30'),expression:"astroImg + '?h=30'"}],staticClass:"text-center centered",attrs:{"height":"30px","width":"30px"}}),_c('p',{staticStyle:{"padding":"10px","font-size":"0.8rem"}},[_vm._v(" Secure Your Spot! Book Your "),_c('strong',[_vm._v(_vm._s(_vm.eventsBrand.title))]),_vm._v(" Experience Now. ")]),_c('br'),_c('RsvpViaDialog',{attrs:{"legend":this.rsvpLegend,"text":"INFO & RSVP","club-config":{
              name: _vm.eventsBrand.title,
              logo: _vm.eventsBrand.metadata.main_image.imgix_url,
            }}}),_c('br'),_c('small',[_vm._v("event info | tables | rsvp | tickets")]),_c('br')],1)],2):_vm._e(),_c('section',[(_vm.pastParties.length)?_c('div',{staticStyle:{"margin-top":"20px"},attrs:{"id":"past-parties"}},[_c('h4',{staticClass:"section-header text-left"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.logo + '?w=40&h=40&fit=clamp'),expression:"this.logo + '?w=40&h=40&fit=clamp'"}],staticStyle:{"border-radius":"300px"},attrs:{"height":"40px","width":"40px","alt":this.eventsBrand.title}}),_vm._v(" Past Events ")]),(_vm.pastParties.length >= 6)?_c('div',{staticClass:"text-left"},_vm._l((_vm.pastParties),function(event){return _c('router-link',{key:event.slug,attrs:{"to":{ name: 'Event', params: { slug: event.slug } }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  event.metadata.main_image.imgix_url + '?w=60&h=60&fit=clamp'
                ),expression:"\n                  event.metadata.main_image.imgix_url + '?w=60&h=60&fit=clamp'\n                "}],staticClass:"carrousel-image",attrs:{"alt":'PAST EVENTS - ' + event.title,"height":"60px","width":"60px"}})])}),1):_vm._e(),(_vm.pastParties.length < 6)?_c('div',{staticClass:"text-left"},[_c('hr'),_vm._l((_vm.pastParties),function(event,idx){return _c('MiniEventCard',{key:event._id,staticClass:"card-animation-on-hover animation-transition-general animate-bounce",attrs:{"event":event,"with-venue":"true","short":"true","past":"true","idx":idx}})})],2):_vm._e()]):_vm._e()]),_c('br'),(this.photos)?_c('section',{staticClass:"row d-xl-none text-left"},[_c('h4',{staticClass:"section-header"},[_c('router-link',{attrs:{"to":{ path: '/l?u=' + _vm.instagramProfileLink }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.instagramImage + '?w=40w=40&fit=clamp'),expression:"this.instagramImage + '?w=40w=40&fit=clamp'"}],attrs:{"height":"20px","width":"20px","alt":this.title}}),_c('small',[_vm._v(" "+_vm._s(_vm.instagramProfile))])])],1),_c('hr',{staticStyle:{"height":"2px"},style:({ backgroundColor: this.brandColor })}),_c('carrousel',{attrs:{"folder":this.photos,"color":this.brandColor}}),_c('div',{staticClass:"card text-center centered",staticStyle:{"max-width":"600px"}},[_c('div',{staticStyle:{"margin-top":"20px"},domProps:{"innerHTML":_vm._s(_vm.eventsBrand.metadata.description)}})])],1):_vm._e(),(_vm.video)?_c('section',{staticClass:"card row",staticStyle:{"margin-top":"35px"},style:({
          background: ("linear-gradient(" + (this.secondaryColor) + ", black)"),
        })},[_c('br'),_c('h4',{staticClass:"text-left section-header"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.logo + '?w=40&h=40&fit=clamp'),expression:"this.logo + '?w=40&h=40&fit=clamp'"}],staticStyle:{"border-radius":"300px"},attrs:{"alt":this.eventsBrand.title,"height":"40px","width":"40px"}}),_vm._v(" ACTION ")]),_c('hr',{staticStyle:{"height":"1px","margin-top":"-10px"},style:({ backgroundColor: this.brandColor })}),_c('club-video-album',{attrs:{"video":_vm.video}})],1):_vm._e(),(_vm.links.length)?_c('section',{staticClass:"d-inline",style:({
          background: ("linear-gradient(" + (this.secondaryColor) + ", black)"),
        })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.djImage),expression:"this.djImage"}],staticClass:"pull-right",attrs:{"alt":"A DJ presenting the brand links & resources","height":"100px"}}),_c('h4',{staticClass:"section-header text-smart"},[_vm._v("RESOURCES")]),_c('br'),_c('DynamicLinks',{style:({
            background: ("linear-gradient(45deg, black, " + (this.brandColor) + ")"),
          }),attrs:{"links":_vm.links}}),_c('br')],1):_vm._e(),_c('section',{staticClass:"card centered"},[_c('p',{staticStyle:{"color":"#fff !important","width":"300px","margin-left":"auto","margin-right":"auto","left":"0","right":"0"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.astroImg + '?h=50'),expression:"astroImg + '?h=50'"}],staticStyle:{"margin-left":"auto","margin-right":"auto","left":"0","right":"0","margin-top":"20px"},attrs:{"height":"50px","width":"50px","alt":"tulum party support"}}),_c('br'),_c('br'),_c('strong',{staticClass:"tulum-party-title-alt",staticStyle:{"font-size":"1.4rem"}},[_vm._v("Let's the party begin !")]),_c('br'),_c('br'),_vm._v(" For "),_c('strong',[_vm._v("reservations")]),_vm._v(", or just to enquire, A simple message is all we desire. ")]),_c('Reservations',{attrs:{"identifier":this.eventsBrand.slug,"color":this.brandColor}})],1)],1),_c('aside',{staticClass:"card col-4 col-lg-4 d-none d-lg-inline"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 text-center"},[_c('h4',{staticClass:"text-center tulum-party-title-alt",staticStyle:{"font-weight":"bold","font-size":"1.3rem"},style:({ backgroundColor: this.brandColor })},[_vm._v(" TABLES & RSVP ")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.astroImg + '?h=30'),expression:"astroImg + '?h=30'"}],staticClass:"text-center centered",attrs:{"height":"30px","width":"30px"}}),_c('br'),_c('p',[_vm._v(" Secure Your Spot! Book Your "),_c('strong',[_vm._v(_vm._s(_vm.eventsBrand.title))]),_vm._v(" Experience Now. ")]),_c('br'),_c('RsvpViaDialog',{attrs:{"legend":this.rsvpLegend,"text":"INFO & RSVP","club-config":{
              name: _vm.eventsBrand.title,
              logo: _vm.eventsBrand.metadata.main_image.imgix_url,
            }}}),_c('br'),_c('br')],1),_c('br')]),(this.photos)?_c('section',{staticClass:"row"},[_c('h4',{staticClass:"section-header"},[_c('router-link',{attrs:{"to":{ path: '/l?u=' + _vm.eventsBrand.metadata.instagram_profile }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.instagramImage + '?w=40w=40&fit=clamp'),expression:"this.instagramImage + '?w=40w=40&fit=clamp'"}],attrs:{"height":"20px","width":"20px","alt":'Instagram Logo'}}),_c('small',[_vm._v(" "+_vm._s(_vm.instagramProfile))])])],1),_c('carrousel',{attrs:{"folder":this.photos,"color":this.brandColor}})],1):_vm._e(),_c('section',{staticStyle:{"margin-top":"30px"},style:({ background: ("linear-gradient(" + (this.brandColor) + ", black)") })},[(_vm.incomingParties.length)?_c('h4',{staticClass:"section-header",attrs:{"id":"incoming-events"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.logo + '?w=40&h=40&fit=clamp'),expression:"this.logo + '?w=40&h=40&fit=clamp'"}],staticStyle:{"border-radius":"300px"},attrs:{"height":"40px","width":"40px"}}),_vm._v("Upcoming Events ")]):_vm._e(),_vm._l((_vm.incomingParties),function(event,idx){return _c('MiniEventCard',{key:event._id,staticClass:"card-animation-on-hover",attrs:{"event":event,"short":"true","without-brand":true,"idx":idx}})})],2),_c('br'),(_vm.links.length)?_c('section',{style:({ background: ("linear-gradient(black, " + (this.brandColor) + ")") })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.djImage),expression:"this.djImage"}],staticClass:"pull-right",staticStyle:{"margin-top":"-50px"},attrs:{"alt":"A DJ presenting the brand links & resources","height":"100px"}}),_c('h4',{staticClass:"section-header"},[_vm._v("RESOURCES")]),_c('DynamicLinks',{style:({
            background: ("linear-gradient( 45deg, '#1C1C1F', " + (this.brandColor) + ")"),
          }),attrs:{"links":_vm.links}})],1):_vm._e(),_c('br'),_c('section',[_c('h4',{staticClass:"section-header"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(this.djImage),expression:"this.djImage"}],staticClass:"pull-right",staticStyle:{"margin-top":"-50px"},attrs:{"alt":"A DJ presenting the brand links & resources","height":"100px"}}),_vm._v(" WHAT'S HOT ")]),_c('div',{staticStyle:{"width":"100%"},style:({
            background: ("linear-gradient( 45deg, '#1C1C1F', " + (this.brandColor) + ")"),
          })},[_c('BrandCollection')],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"section-header",staticStyle:{"font-weight":"bold"}},[_c('i',{staticClass:"tim-icons icon-chat-33"}),_vm._v(" INFO & RSVP ")])}]

export { render, staticRenderFns }